<template>
  <div class="zong">
    <div class="dingbu">
      <div class="biao"><img src="./logo.png" width="200px" height="auto" /></div>
      <div class="ming"></div>
      <div class="shouye"><a href="#" style="color: inherit">首页</a></div>
      <div class="sai">
        <div class="a1"><a  style="color: inherit">解决方案</a></div>
        <div class="a2"><a  style="color: inherit">创新技术</a></div>
        <div class="a3"><a  style="color: inherit">新闻资讯</a></div>
        <div class="a4"><a  style="color: inherit">关于立讯</a></div>
        <div class="a5"><a  style="color: inherit">加入我们</a></div>
      </div>
    </div>

    <div class="yi">
      <h1>立讯精密（安徽）有限公司</h1>
      <div class="wenzi">
        <h3 style="margin-top:30px">拥抱科技连接世界美好</h3>
        <h4>致力于成为全球领先的解决方案提供商，帮助客户创造更多价值</h4>

      </div>
    </div>
    <div class="yaowen">
      <h2>公司要闻</h2>
      <div class="dian"><img loading="lazy" src="./jieshao.png" width="200" height="20" /></div>
      <div class="tongzhi">
        <span class="news"><img loading="lazy" src="./inform.png" /></span>
        <span class="naxin">
          <p class="n1">纳新通知</p>
          <p class="n2">
            公司纳新开始啦！请有意向加入立讯的同学及时填写申请报名表
          </p>
          <p class="n3"><a href="#" style="color: inherit">马上报名</a></p>
        </span>
        <span class="nn"><img loading="lazy" src="./news.png" width="300" height="200" /></span>
      </div>
    </div>
    <div class="jieshao">
      <div class="js">公司简介</div>
      <div class="d"><img loading="lazy" src="./jieshao.png" width="200" height="20" /></div>
      <div class="da">
        <div class="jst"><img loading="lazy" src="./info.png" width="300" height="250" /></div>
        <div class="xjst">
          <span class="chuang"
            ><img loading="lazy" src="./创新.png" width="50" height="65"
          /></span>

          <span class="chuang"
            ><img loading="lazy" src="./创意.png" width="50" height="65"
          /></span>

          <span class="chuang"
            ><img loading="lazy" src="./创造.png" width="50" height="65"
          /></span>
        </div>
        <div class="jsz">
          <p style="color: rgb(50, 50, 53)">
            我们始终将研发创新视为公司最核心的发展战略之一，持续坚持对研发和技术的前瞻性投入，努力革新传统制造工艺，不断提升智能化生产水平，加强前端技术、工艺的开拓与应用，长期坚持底层材料、加工工艺、智能设备等前沿技术领域的创新和迭代，实现平台化、智能化，推动并引领行业技术进步
          </p>
          <p class="p1">
            我们从研发到制造、全员导入精益理念，持续提升管理水平、提升效率、质量、快速交付，领先行业标杆
          </p>
          <p class="p2">
            立讯紧扣智能制造逻辑，持续赋能自动化工艺和数字化应用，深度聚焦底层技术和产品功能的复合开发，不断夯实声、光、电、热、磁、射频、表面贴装、系统封装，机加工等底层技术和能力，追求和实现客户产品极致的价值赋能。
          </p>
        </div>
      </div>
    </div>
    <div class="jineng">
      <h2>技能</h2>
      <div class="dian"><img loading="lazy" src="./jieshao.png" width="200" height="20" /></div>
      <div class="jnt">
        <img loading="lazy" src="./skill-all.png" width="700" height="400" /><span class="skill"
          ><img loading="lazy" src="./skill.png" width="300" height="200"
        /></span>
      </div>
    </div>
    <div class="js">公司图片</div>
    <div class="d"><img loading="lazy" src="./jieshao.png" width="200" height="20" /></div>

    <div class="huodong">
      <ul class="slides">
        <input type="radio" id="control-1" name="control" checked />
        <input type="radio" id="control-2" name="control" />
        <input type="radio" id="control-3" name="control" />

        <li class="slide"></li>
        <li class="slide"></li>
        <li class="slide"></li>
        <div class="controls-visible">
          <label for="control-1"></label>
          <label for="control-2"></label>
          <label for="control-3"></label>
        </div>
      </ul>
    </div>

    <ul class="circle"></ul>
    <div class="zi">
      <h5 class="ww">如果你富有创造和激情</h5>
      <h5 class="ww">渴望温暖活力的大家庭</h5>
      <h5 class="ww">想要学习生活丰富充实</h5>
      <h5 class="ww">希望拥有提升自我以及</h5>
      <h5 class="ww">实现自我价值的平台</h5>
      <h5 class="ww">这里，是你最好的平台！</h5>
      <h5 class="ww">给你一个锻炼的舞台，成就不一样的精彩！</h5>
    </div>
    <div class="end">
      <div class="sosd">
        <div><img loading="lazy" src="./logo.png" width="200px" height="auto" /></div>
        <div class="ss">立讯精密</div>
      </div>
      <div class="wangzhan">
        <div class="jiaoyu">
          <p class="aa">解决方案<br /></p>
          <p class="bb">
            通信互联<br />数据中心<br />汽车产业<br />消费电子
          </p>
        </div>
        <div class="jiaoyu">
          <p class="aa">创新技术<br /></p>
          <p class="bb">
            智能制造<br />数字化管理<br />精益化运营<br />价值赋能
          </p>
        </div>
        <div class="jiaoyu">
          <p class="aa">新闻资讯<br /></p>
          <p class="bb">公司新闻</p>
          <p class="bb">展会活动</p>
        </div>
        <div class="jiaoyu">
          <p class="aa">可持续发展<br /></p>
          <p class="bb">我们的行动</p>
          <p class="bb">荣誉与动态</p>
          <p class="bb">报告与政策</p>
        </div>
        <div class="jiaoyu">
          <p class="aa">投资者关系<br /></p>
          <p class="bb">公司公告</p>
          <p class="bb">定期报告</p>
          <p class="bb">投资者调研纪要</p>
        </div>
        <div class="jiaoyu">
          <p class="aa">关于立讯<br /></p>
          <p class="bb">公司概况</p>
          <p class="bb">加入我们</p>
          <p class="bb">联系我们</p>
          <p class="bb">供应商平台</p>
        </div>
      </div>
      <div class="filing">
        <a href="https://beian.miit.gov.cn">皖ICP备2024055551号-2</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data () {
    return {}
  }
}
</script>
<style>
*{
  margin: 0;
  padding: 0;
}
.filing{
  width: 100%;
  height: 50px;
  text-align: center;
}
.zong {
    display: flex;
    flex-direction: column;

}

.dingbu {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    height: 45px;
}

.biao {
    width: 50px;
    height: 45px;
    background-color: transparent;
    padding-top: 10px;
}

.ming {
    width: 536px;
    height: 45px;
    background-color: transparent;
    padding-top: 10px;
    font-size: 1.5em;
    font-weight: 800;
    background-image: -webkit-linear-gradient(left, rgb(13, 88, 173), rgb(13, 196, 228));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.shouye {
    background-color: transparent;
    width: 59px;
    height: 40px;
    padding-top: 14px;
    padding-left: 15px;
    font-size: 1.20em;
    font-weight: 800;
    color: rgb(92, 96, 97);
    border-bottom: 3px solid rgb(22, 121, 235);

}

a {
    text-decoration: none;
    color: rgb(92, 96, 97);
}

.denglu {
    width: 90px;
    height: 27px;
    background-color: rgb(7, 67, 134);
    border: 1px solid rgb(7, 67, 134);
    border-radius: 5px;
    color: white;
    margin-right: 20px;
    text-align: center;
    margin-top: 13px;
    font-weight: 800;
    padding-top: 1px;
    font-size: 1.1em;
}

.sai {
    background-color: transparent;
    display: flex;
    width: 530px;
    height: 45px;
    padding-top: 14px;
    font-size: 1.20em;
    font-weight: 800;
    color: rgb(92, 96, 97);

}

.a1 {
    flex-grow: 1;
    padding-left: 15px;

}

.a2 {
    flex-grow: 1;
    padding-left: 15px;
}

.a3 {
    flex-grow: 1;
    padding-left: 15px;
}

.a4 {
    flex-grow: 1;
    padding-left: 15px;
}

.a5 {
    flex-grow: 1;
    padding-left: 10px;
}

.yi {
    height: 680.0px;
    background-image: url("./bg.png");
    /* background-size: 1590px, 1450px; */
    padding-top: 10px;
    background-position: -190px, -190px;
}

.wenzi {
    background-color: transparent;
    width: 600px;
    height: 200px;
    display: flex;
    flex-direction: column;
}

h1 {
    margin-top: 210px;
    height: 50px;
    width: 600px;
    background-image: -webkit-linear-gradient(left, rgb(3, 47, 97), rgb(25, 161, 240));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 25px;
    font-size: 2.2em;
}

h3 {
    text-align: center;
    font-size: 1.5em;
    margin-top: 12px;
    color: rgb(81, 85, 85);
}

h4 {
    text-align: center;
    margin-top: 15px;
    color: rgb(117, 121, 121);
    font-weight: 530;
    font-size: 1.1em;
}

.baoming {
    margin-top: 15px;
    width: 250px;
    height: 40px;
    background-color: rgb(7, 67, 134);
    border: 1px solid rgb(7, 67, 134);
    border-radius: 10px;
    color: white;
    margin-left: 170px;
    text-align: center;
    font-weight: 800;
    font-size: 1.15em;
    line-height: 40px;
}

.yaowen {
    display: flex;
    flex-direction: column;
    height: 550px;
    background-color: white;
}

h2 {
    margin-top: 100px;
    color: rgb(73, 159, 240);
    font-weight: 600;
    font-size: 1.3em;
    margin-left: 50px;
}

.tongzhi {
    background-color: white;
    height: 200px;
    margin-top: 120px;
    display: flex;

}

.news {
    margin-top: 20px;
}

.naxin {
    margin-left: 10px;
    width: 1000px;
}

.nn {
    margin-left: 200px;
}

.n1 {
    color: rgb(12, 97, 167);
    font-weight: 600;
    font-size: 1em;
}

.n2 {
    color: rgb(67, 70, 71);
    font-weight: 600;
    font-size: 1.3em;
}

.n3 {
    color: rgb(126, 164, 196);
    font-weight: 600;
    font-size: 1.2em;
}

.jieshao {
    display: flex;
    flex-direction: column;
    height: 900px;
    background-color: white;
}

.js {
    margin-top: 90px;
    color: rgb(73, 159, 240);
    font-weight: 600;
    font-size: 1.3em;
    margin-left: 1080px;
}

.d {
    margin-top: 15px;
    margin-left: 1040px;
}

.da {
    display: flex;

}

.jst {
    margin-top: 200px;
}

.xjst {
    margin-left: 300px;
    display: flex;
    flex-direction: column;
}

.chuang {
    margin-top: 120px;
}

.jsz {
    margin-top: 110px;
    margin-left: 50px;
    line-height: 30px;
}

strong {
    color: black;
}

.p1 {
    margin-top: 120px;
}

.p2 {
    margin-top: 130px;
}

.jineng {
    height: 600px;
}

.jnt {
    display: flex;
}

.skill {
    margin-left: 250px;
}

.huodong {
    margin-top: 50px;
    height: 700px;
    width: 1280px;
    display: flex;

}

ul.slides {
    position: relative;
    height: 700px;
    width: 1280px;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

li.slide {
    margin: 0;
    padding: 0;
    width: inherit;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    transition: .5s trasform ease-in-out;
}

.slide:nth-of-type(1) {
    background-image: url("1.jpg ");
    background-size: 1280px, 700px;
}

.slide:nth-of-type(2) {
    background-image: url("2.jpg ");
    left: 100%;
    background-size: 1280px,
        700px;
}

.slide:nth-of-type(3) {
    background-image: url("3.jpg ");
    left: 200%;
    background-size: 1280px,
        700px;
}

input[type="radio"] {
    position: relative;
    z-index: 100;
    display: none;
}

.controls-visible {
    position: absolute;
    width: 100%;
    bottom: 12px;
    text-align: center;
}

.controls-visible label {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border-radius: 50%;
    margin: 0, 3px;
    border: 2px solid rgb(5, 5, 5);
}

.slides input[type="radio"]:nth-of-type(1):checked~.controls-visible label:nth-of-type(1) {
    background-color: rgb(0, 0, 0);
}

.slides input[type="radio"]:nth-of-type(2):checked~.controls-visible label:nth-of-type(2) {
    background-color: rgb(0, 0, 0);
}

.slides input[type="radio"]:nth-of-type(3):checked~.controls-visible label:nth-of-type(3) {
    background-color: rgb(0, 0, 0);
}

.slides input[type="radio"]:nth-of-type(1):checked~.slide {
    transform: translateX(0%);
}

.slides input[type="radio"]:nth-of-type(2):checked~.slide {
    transform: translateX(-100%);
}

.slides input[type="radio"]:nth-of-type(3):checked~.slide {
    transform: translateX(-200%);
}

.circle {}

.zi {
    margin-top: 100px;
    margin-left: 380px;
    height: 500px;
    width: 500px;
    background-color: rgb(255, 255, 255);
    text-align: center;
}

.ww {
    background-color: transparent;
    font-size: 1.3em;
    font-weight: 800;
    background-image: -webkit-linear-gradient(left, rgb(39, 121, 214), rgb(113, 56, 160));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.end {
    //height: 400px;
    display: flex;
    flex-direction: column;
    background-color: rgb(13, 49, 83);
}

.sosd {
    display: flex;
    margin-top: 30px;
    margin-left: 90px;
    height: 40px;
}

.ss {
    margin-left: 10px;
    margin-top: 10px;
    color: rgb(128, 131, 131);
}

.wangzhan {
    display: flex;
    margin-left: 90px;
    margin-top: 40px;
}

.jiaoyu {
    width: 300px;
    color: white;
}

.aa {
    font-size: 1.1em;
    font-weight: 800;

}

.bb {
    font-size: 0.9em;
    font-weight: 200;
    line-height: 1.8;
}

.tuandui {
    font-size: 0.9em;
    font-weight: 200;
    color: white;
    margin-left: 90px;
    margin-top: 60px;
}
</style>
